import React from 'react'

const Hero = ({ children }) => {
    return (
        <>
            <div className="w-full h-screen relative">{children}</div>
        </>
    )
}

export { Hero }
