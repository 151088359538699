import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'
import { Button } from '../Button'

const ImageBlocks = () => {
    return (
        <div className="py-14 lg:py-28 bg-strong relative -top-[8rem]">
            <div className="custom-container flex flex-col lg:flex-row space-y-10 space-x-0 lg:space-x-28 lg:space-y-0 items-start">
                <div className="flex w-full lg:w-2/4">
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                        alt="Hero image"
                        layout="constrained"
                        aspectRatio={4 / 3}
                        placeholder="blurred"
                        src={'../../images/game.png'}
                        formats={['auto', 'webp', 'avif']}
                    />
                </div>
                <div className="flex w-full lg:w-2/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <div className="flex flex-col space-y-10">
                            <p className="text-light">
                                In Blockxer, everything is an NFT. Your hero
                                character? An NFT. The scenery in the
                                background? An NFT. That rusty knife you just
                                picked up on the street to defend yourself
                                against attack? You guessed it – also an NFT.
                                <br />
                                <br />
                                Just like blockchain technology, Blockxer is
                                designed to evolve. We built this retro-styled
                                2D beat ‘em up arcade game to be expandable, and
                                while currently in beta, future releases will
                                give gamers the ability to add new characters,
                                weapons and even levels, and swap or trade them
                                with other players.
                                <br />
                                <br />
                                What started as a crazy idea in one of our
                                regular Thursday meetings is now a full-blown
                                (and highly addictive) game. It’s also a
                                playable demonstration of the ways in which a
                                decentralized model can empower communities of
                                fans, handing control back to gamers.
                            </p>
                            <Button
                                label="Go play"
                                url="https://blockxer.com/"
                            />
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export { ImageBlocks }
