import React from 'react'
import { Fade } from 'react-awesome-reveal'

const CallToAction = ({ title, description }) => {
    return (
        <div className="custom-container w-full flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-x-28 pb-14 lg:pb-28 relative -top-[8rem]">
            <Fade direction="up" triggerOnce delay={300}>
                <h1 className="pb-10 lg:pb-0">{title}</h1>
            </Fade>
            <Fade direction="up" triggerOnce delay={200}>
                <div className="">
                    <p className="text-black pb-10">{description}</p>
                </div>
            </Fade>
        </div>
    )
}

export { CallToAction }
