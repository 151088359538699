import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Head } from '../components/MetaInfo'
import { Hero } from '../components/Hero'
import { HeroVideo } from '../components/HeroVideo'
import { CallToAction } from '../components/CallToAction'
import { ImageBlocks } from '../components/ImageBlocks'
import { Stats } from '../components/Stats'

const App = () => {
    return (
        <>
            <Head
                title="Blockxer"
                description="A classic beat ‘em-up arcade game, but with a twist: It’s a dynamic, NFT-powered game themed around well-known memes, where players face off against some of the biggest and most irritating villains in crypto."
            />
            <main>
                <Hero>
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        layout="fullWidth"
                        placeholder="blurred"
                        alt="Hero image"
                        transformOptions="fit"
                        src={'../images/hero_image.png'}
                        formats={['auto', 'webp', 'avif']}
                        className="static_override"
                    />
                </Hero>
                <HeroVideo video_url="https://player.vimeo.com/video/780816917?h=97bb510610&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
                <CallToAction
                    title="Crypto goes retro in Blockxer: The first ever NFT-powered beat’em up arcade game"
                    description="We created Blockxer as a tribute to 2D 1990s beat‘em-up arcade games, but with a twist: It’s a dynamic, NFT-powered game themed around well-known memes, where players face off against some of the biggest and most irritating villains in crypto. To get started, just link your crypto wallet, claim your free cartridge, and join the fight!"
                />
                <ImageBlocks />
                <div className="pb-28 pt-10 lg:pb-[200px] lg:pt-20 bg-black relative -top-[8rem]">
                    <Stats color="text-red" />
                </div>
            </main>
        </>
    )
}

export default App
