import React from 'react'
import ReactPlayer from 'react-player'
import * as styles from './styles.module.scss'

const HeroVideo = ({ video_url }) => {
    return (
        <div className="custom-container pb-10 lg:pb-28 relative -top-[8rem]">
            <div className={styles.video_wr}>
                <div className={styles.player_wrapper}>
                    <ReactPlayer
                        playsinline={false}
                        background={1}
                        url={video_url}
                        controls={true}
                        width="100%"
                        height="100%"
                        className={styles.react_player}
                        loop={false}
                    />
                </div>
            </div>
        </div>
    )
}

export { HeroVideo }
