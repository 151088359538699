import React from 'react'
import Helmet from 'react-helmet'
import metaimage from '../../images/Meta_Image_V1.png'

const Head = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta
                property="og:url"
                content="https://blockxer.wedothe.work"
            />
            <meta property="og:image" content={metaimage} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
                property="twitter:url"
                content="https://blockxer.wedothe.work"
            />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={metaimage} />
        </Helmet>
    )
}

export { Head }
