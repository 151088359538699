import React from 'react'
import { ArrowIcon } from './ArrowIcon'
import * as styles from './styles.module.scss'

const Button = ({ label, url }) => {
    return (
        <a href={url}>
            <div
                className={`${styles.button_st} flex bg-red items-center justify-center py-[18px] px-12 lg:w-fit rounded-md`}
            >
                <p className={`${styles.button_label} text-white`}>{label}</p>

                <ArrowIcon />
            </div>
        </a>
    )
}

export { Button }
