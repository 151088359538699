import React from 'react'
import * as styles from './styles.module.scss'

const data = {
    row1: [
        {
            title: '87k',
            subtitle: 'Unique sessions',
        },
        {
            title: '207k',
            subtitle: 'Total page views',
        },
        {
            title: '12:17',
            subtitle: 'Average engagement time',
        },
    ],
}

const Stats = ({ color }) => {
    return (
        <div className="custom-container">
            <h1 className="w-full text-left lg:text-center pb-10 lg:pb-20">
                <span className="text-light">The </span>
                <span className={color}>Stats</span>
            </h1>
            <div className="grid grid-flow-row gap-y-10 lg:gap-y-20">
                {/* Row 1 */}
                <div className="grid grid-rows-1">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-10 gap-x-0 lg:gap-x-10 lg:gap-y-0">
                        {React.Children.toArray(
                            data.row1.map((dat, i, { length }) => {
                                if (length - 1 === i) {
                                    // Last one.
                                    return (
                                        <div className="flex flex-col lg:flex-row justify-between">
                                            <div className="">
                                                <h2 className={color}>
                                                    {dat.title}
                                                </h2>
                                                <p className="bodyM">
                                                    {dat.subtitle}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles.border_visible
                                                }
                                            >
                                                <div className="hidden lg:block lg:w-10 lg:h-auto"></div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    // Not last one.
                                    return (
                                        <div className="flex flex-col lg:flex-row justify-between">
                                            <div className="">
                                                <h2 className={color}>
                                                    {dat.title}
                                                </h2>
                                                <p className="bodyM">
                                                    {dat.subtitle}
                                                </p>
                                            </div>
                                            <div
                                                className={styles.column_border}
                                            >
                                                <div className="h-10 w-auto lg:w-10 lg:h-auto"></div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Stats }
